import React from "react";
import Layout from "../components/Layout";

// Components
import { Link, graphql } from "gatsby";
import Seo from "../components/Seo";

const Collections = function ({ data }) {
  const collectionList = data.allContentfulCollection.edges;

  return (
    <Layout>
      <div>
        <Seo title={`Kingston School of Art Foundation 2021 • Collections`} />
        <div>
          <h2>Collections</h2>
          <ul>
            {/* For each collection */}
            {collectionList.map(function (collection) {
              return (
                <li key={collection.node.title}>
                  <h3>{collection.node.title}</h3>

                  {collection.node.student.map(function (student) {
                    return (
                      <ul>
                        <Link to={`/students/${student.slug}`}>
                          <li key={student.studentName}>
                            {student.studentName}
                          </li>
                        </Link>
                      </ul>
                    );
                  })}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default Collections;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulCollection {
      edges {
        node {
          title
          student {
            studentName
            slug
          }
        }
      }
    }
  }
`;

// {collectionList.map((collection) => {

//   return collection.node.student.map((student) => (
//     <>
//       <h3>{collection.node.title}</h3>;
//       <ul>
//         <li>{student.studentName}</li>
//       </ul>
//     </>
//   ));
// })}
